<template>
  <div class="page-wallet">
    <b-row class="p-0">
      <b-col cols="12" class="p-0">
        <div class="list-coin-seciton">
          <div class="box-coin" v-for="coin in Coin" :key="`coin-id-${coin.Currency_ID}`">
            <div class="coin">
              <div class="left">
                <div class="icon coin-img">
                  <img src="https://media.eggsbook.com/coin/USDT.png?v=1636342107306" alt="" />
                </div>
              </div>
              <div class="right">
                <div class="coin-name">{{ coin.Currency_Name }}</div>
                <div class="coin-balance coin-info" v-if="false">
                  <span class="title">Balance:</span>
                  <span class="value">5000</span>
                </div>
                <div class="coin-price coin-info">
                  <span class="title">Price:</span>
                  <span class="value">{{ coin.Currency_Price }}</span>
                </div>
                <div class="coin-action">
                  <b-button
                    variant="outline-succes"
                    class="btn-egg"
                    @click="showDeposit(coin.Currency_ID)"
                    v-if="coin.Currency_StatusDeposit == 1"
                  >
                    Deposit
                  </b-button>
                  <b-button
                    variant="info"
                    @click="showWithdraw"
                    v-if="coin.Currency_StatusWithdraw == 1"
                  >
                    Withdraw
                  </b-button>
                  <b-button
                    variant="warning"
                    @click="showTransfer"
                    v-if="coin.Currency_StatusTransfer == 1"
                  >
                    Transfer
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col class="12">
        <div class="history-box">
          <div class="title">History Transaction</div>
          <div class="history-box-body">
            <b-table striped hover :items="items" :fields="fields"></b-table>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal
      id="deposit"
      :hide-header="true"
      :hide-footer="true"
      modal-class="d-sm-flex justify-content-sm-center align-items-sm-center"
    >
      <Deposit @closePopup="$bvModal.hide('deposit')" :coinID="deposit.coin" />
    </b-modal>
    <b-modal
      id="withdraw"
      :hide-header="true"
      :hide-footer="true"
      modal-class="d-sm-flex justify-content-sm-center align-items-sm-center"
    >
      <Withdraw @closePopup="$bvModal.hide('withdraw')" />
    </b-modal>
    <b-modal
      id="transfer"
      :hide-header="true"
      :hide-footer="true"
      modal-class="d-sm-flex justify-content-sm-center align-items-sm-center"
    >
      <Transfer @closePopup="$bvModal.hide('transfer')" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Deposit from '@/components/wallet/Deposit.vue';
import Withdraw from '@/components/wallet/Withdraw.vue';
import Transfer from '@/components/wallet/Transfer.vue';

export default {
  components: { Deposit, Withdraw, Transfer },
  data() {
    return {
      deposit: {
        coin: null,
      },
      fields: [
        {
          key: 'last_name',
          sortable: true,
        },
        {
          key: 'first_name',
          sortable: false,
        },
        {
          key: 'age',
          label: 'Person age',
          sortable: true,
          // Variant applies to the whole column, including the header and footer
          variant: 'danger',
        },
      ],
      items: [
        {
          isActive: true,
          age: 40,
          first_name: 'Dickerson',
          last_name: 'Macdonald',
        },
        {
          isActive: false,
          age: 21,
          first_name: 'Larsen',
          last_name: 'Shaw',
        },
        {
          isActive: false,
          age: 89,
          first_name: 'Geneva',
          last_name: 'Wilson',
        },
        {
          isActive: true,
          age: 38,
          first_name: 'Jami',
          last_name: 'Carney',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      Coin: 'wallet/Coin',
    }),
  },
  methods: {
    showDeposit(coin) {
      this.deposit.coin = coin ?? 5;
      this.$bvModal.show('deposit');
    },
    showWithdraw(coin) {
      this.$bvModal.show('withdraw');
    },
    showTransfer(coin) {
      this.$bvModal.show('transfer');
    },
  },
  created() {},
};
</script>

<style lang="scss">
.page-wallet {
  .list-coin-seciton {
    width: 100%;
    min-height: 250px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .box-coin {
      flex: 0 0 33.3%;
      max-width: 33.3%;
      .coin {
        box-shadow: 0px 0px 10px 2px #00000038;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        padding: 15px 0;
        margin-bottom: 15px;
        margin: 0 auto 15px;
        position: relative;
        width: calc(100% - 20px);
        // min-width: 410px;

        .left {
          position: absolute;
          top: 10px;
          right: 10px;
          .icon {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 5px;
            width: 100%;
            border-radius: 50%;
            box-shadow: 0px 2px 10px 2px #0000006e;
            img {
              width: 100%;
              max-width: 40px;
              height: auto;
              filter: drop-shadow(0px 0px 10px 2px #00000050);
            }
          }
        }
        .right {
          flex: 1 1 auto;
          padding-left: 5px;
          .coin-name {
            font-weight: 600;
            font-size: 20px;
            color: #2f394e;
            text-align: left;
            padding-left: 10px;
          }
          .coin-info {
            padding-left: 10px;
            .title {
              font-weight: 600;
              color: #2f394e;
              opacity: 0.9;
              text-align: center;
              text-transform: uppercase;
              margin-right: 5px;
              font-size: 15px;
            }
            .value {
              font-weight: 600;
              font-size: 19px;
              color: #2f394e;
              text-align: center;
            }
          }
          .coin-action {
            margin-top: 15px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-wrap: wrap;
            button {
              flex: 1;
              min-width: 90px;
              max-width: 120px;
              height: 40px;
              box-shadow: 0 2px 6px 0 #ccc;
              font-weight: 600;
              margin: 5px 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              &:hover,
              &:focus,
              &:focus-visible {
                box-shadow: 0 5px 18px rgba(0, 0, 0, 0.2), 0 10px 12px rgba(0, 0, 0, 0.2);
              }
            }
          }
        }
      }
    }
    @media (max-width: 1600px) {
      .box-coin {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
    @media (max-width: 991px) {
      .box-coin {
        flex: 0 0 50%;
        max-width: 50%;
        .coin {
          width: calc(100% - 10px);
          max-width: 520px;
        }
      }
    }
    @media (max-width: 767px) {
      .box-coin {
        flex: 0 0 100%;
        max-width: 100%;
        .coin {
          width: 100%;
          max-width: 520px;
        }
      }
    }
  }
  .wallet-action {
    width: 100%;
    min-height: 250px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 7px 10px 2px #00000038;
    position: relative;
  }
  .history-box {
    box-shadow: 0px 0px 10px 2px #00000038;
    background-color: #fff;
    min-height: 250px;
    border-radius: 10px;
    padding: 15p;
    width: 100%;
    .title {
      font-weight: 600;
      font-size: 22px;
      color: #2f394e;
      text-align: left;
      padding: 10px 15px;
      border-bottom: 3px solid rgba($color: #000000, $alpha: 0.2);
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }
  }
}
</style>
